@import '../../styles/theme.scss';

.social-items__container {
  margin-bottom: 50px;

  .profile-social {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none !important;
    padding:2px;
    position: relative;
  
    &:hover {
      color: white !important;
    }
  
    &-img {
      width: 75px;
    }
  
    &-username__wrapper {
      padding-left: 1rem;
      flex:1;
      text-align: center;
      span {
        font-weight: 500;
      }
    }
  
    .profile-social-username__wrapper {
      &.social-userName-character {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: rgba(31, 35, 40,0.3);
        border-radius: 15px;
        border: 1px solid #000000;
        height: 69px;
        margin-left: 26px;
        padding-left: 35px;
        padding-right: 5px;
        display: flex;
        align-items: center;
        z-index: 9;
      }
  
      .profile-social-username {
        width: 100%;
        display: inline-block;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 22px;
        font-weight: 700;
      }
    }
    .profile-social-img__wrapper {
      position: absolute;
      z-index: 99;
    }
  }

  &.light {
    .profile-social {
      @include light-container('primary');
    }
  }

}

